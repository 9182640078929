import { reactive, ref } from "vue";
import {
  activityLogo,
  CONSTANTS,
  formatDate,
  formatNumber,
  receivedSum,
  totalInt,
} from "@/graphql/utils/utils";
import { Customer, Product } from "@/graphql/types";
import { useTerminalMargins } from "@/components/terminal/terminal-margins";
import { activeActivity } from "@/plugins/i18n";
import { useI18n } from "vue-i18n";
import { AppSource } from "@/data/global";
import { api as fullscreen } from "vue-fullscreen";
import { getDiscountValues } from "./ticket";
import { PrinterOptionsEnum } from "../terminal/terminalEnum";
import { productParams } from "../utils/product";

type PaymentMode = {
  payed: number;
  cost: any;
  isOffered: string;
  netTotal: number;
  offerAmount: any;
  percentage: number;
  sold: number;
  discount: number;
  offerPercentage: number;
  realOffer: number;
};

//Open window printer
function printWindow(body: string) {
  let frame: any = document.getElementById("print-frame");
  frame = frame.contentWindow;
  frame.document.write(body);
  frame.document.close(); //important!,necessary for IE >= 10
  frame.focus(); //IE fix,necessary for IE >= 10*/
  if (frame.addEventListener)
    frame.addEventListener("load", () => frame.print(), true);
  else if (frame.attachEvent) frame.attachEvent("onload", () => frame.print());
  frame.close();
  frame.addEventListener("afterprint", () => {
    if (sessionStorage.getItem(CONSTANTS.fullscreenAfterPrint) == "1") {
      fullscreen.toggle(document.querySelector("html"), {});
    }
  });
}

export const printFrame = (body: string) => {
  if (document.fullscreenElement) {
    document
      .exitFullscreen()
      .then(() => sessionStorage.setItem(CONSTANTS.fullscreenAfterPrint, "1"))
      .catch(() => sessionStorage.removeItem(CONSTANTS.fullscreenAfterPrint))
      .finally(() => printWindow(body));
  } else {
    sessionStorage.removeItem(CONSTANTS.fullscreenAfterPrint);
    printWindow(body);
  }
};

export const printElement = (data: string, margins = "", isId = true) => {
  if (!margins) {
    const { getMargins } = useTerminalMargins();
    margins = getMargins();
  }
  const body = `<!DOCTYPE html>
      <head>
        <style>
            @page {
              margin: ${margins};
              padding: 0;
           }
           /*@page {
              margin: 0 9mm 0 6mm;
              padding: 0;
           }
           @-moz-document url-prefix() {
               @page {
                  margin: 0 1mm;
                  padding: 0;
              }
           }*/
           @media print {
              body {
                  font-family: Arial, serif;
                  margin: 0;
                  padding: 0;
                  font-size: 13px;
                  color: black;
                  word-break: break-all;
              }
          
              #print-ticket {
                  /*width: 57mm*/;
              }
          
              .separator {
                  margin: 3mm 0;
              }
          
              #name {
                  font-weight: bold;
                  font-size: 1.2em;
              }
          
              #desc {
                  font-size: 0.9em;
              }
          
              .ticket {
                  font-weight: bold;
                  font-size: 0.8em;
              }
          
              #ticket {
                  margin: auto;
                  width: 100%;
                  border-collapse: collapse;
              }
          
              #ticket tr td {
                  text-align: left;
                  padding: 4px;
                  border-bottom: dotted 1px black;
              }
          
              #ticket tr:last-child td {
                  border-bottom: none;
              }
          
              .table-content {
                  margin: auto;
                  width: 100%;
                  text-align: right;
                  font-size: 12px;
              }
              .table-content > thead > tr:first-child > th {
                  text-align: center !important;
              }

              .table-content > thead > tr > td {
                font-weight: bold;
              }
          
              .table-content > thead > tr > td {
                  white-space: nowrap;
              }
          
              .table-content > thead > tr > td:first-child {
                  text-align: left;
              }
          
              .table-content > tbody > tr > td {
                  white-space: nowrap;
              }
          
              .table-content > tbody > tr > td:first-child {
                  white-space: normal !important;
                  text-align: left;
              }
          
              .table-content > tfoot > tr > td:first-child {
                  text-align: right;
                  font-weight: bold;
              }
          
              .table-content > tfoot > tr > td:last-child {
                  white-space: nowrap;
              }
          
              .table-content > tfoot > tr:last-child > td:last-child {
                  font-weight: bold;
                  font-size: 1.2em;
              }
          
              #footer {
                  text-align: center;
              }
           }
        </style>
      </head>
      <html lang="en">
        <body>
          ${isId ? document.getElementById(data)?.innerHTML : data}
        </body>
      </html>`;

  printFrame(body);

  return false;
};

export const usePrintV2 = (printRef: boolean) => {
  const { t, tm } = useI18n();
  const paymentDialog = ref(false);
  const btnPrinterEnable = ref(0);

  const customer = ref<Customer>(null);

  const ticketAfterPay = reactive({
    createdAt: null,
    movements: [],
    payments: [],
    number: 0,
    received: 0,
    change: 0,
    amount: 0, // percentage amount
    percentage: 0,
    receivedSum: 0,
    total: 0,
    customer: "",
    status: 0,
  });

  const printAfterPay = ref(false);

  function setupTicket(ticket: any) {
    ticketAfterPay.customer = customer.value?.name;
    if (ticket?.id) {
      const { createdAt, number, status, movements, payments } = ticket;
      ticketAfterPay.movements = movements.map((m, index) => ({
        index,
        name: m.product.name,
        reference: m.product.reference,
        vat: m.vat,
        price: m.price,
        quantity: m.quantity,
      }));
      ticketAfterPay.amount = ticket.amount;
      ticketAfterPay.percentage = ticket.percentage;
      ticketAfterPay.payments = payments;
      ticketAfterPay.received = payments[0]?.received || 0;
      ticketAfterPay.createdAt = payments[0]?.createdAt || createdAt;
      ticketAfterPay.receivedSum = receivedSum(payments);
      ticketAfterPay.total = totalInt(movements);
      ticketAfterPay.number = number;
      ticketAfterPay.status = status;
    } else {
      ticketAfterPay.movements = ticket;
      ticketAfterPay.payments = [];
      ticketAfterPay.createdAt = new Date();
      ticketAfterPay.change = 0;
      ticketAfterPay.received = 0;
      ticketAfterPay.number = 0;
      ticketAfterPay.status = 0;
      ticketAfterPay.receivedSum = 0;
      ticketAfterPay.total = totalInt(ticket);
    }
  }

  function getPaymentMode(ticket: any): PaymentMode {
    const {
      offerAmount,
      offerPercentage,
      total: cost,
      received: payed,
      isOffered,
      sold,
      percentage,
      discount,
      realOffer,
    } = getDiscountValues(ticket);

    const netTotal = cost - offerAmount;
    return {
      payed,
      cost,
      isOffered: isOffered ? `${tm("ticket.status")[6]}`.toUpperCase() : "",
      netTotal,
      offerAmount,
      percentage,
      sold,
      discount,
      offerPercentage,
      realOffer,
    };
  }

  function buildHeader(ticket: any) {
    return `<div id="header">
        ${
          activeActivity.value.logo
            ? `<div id="logo">
           <img src="${activityLogo.value}" style="width: 50mm" alt="" />
        </div>`
            : ""
        }
        <div id="name">${activeActivity.value.name}</div>
        ${
          activeActivity.value.slogan
            ? `<div id="desc">
          ${activeActivity.value.slogan}
        </div>`
            : ``
        }
        <hr class="separator" />
        <table id="ticket">
          ${
            ticket?.number > 0
              ? `          <tr>
            <td>${t("payment.ticketNumber")}</td>
            <td>${ticketAfterPay.number}</td>
          </tr>`
              : ``
          }
          <tr>
            <td>${t("date")}</td>
            <td>${formatDate(ticket.createdAt, t("format.ticketDate"))}</td>
          </tr>
          <tr>
            <td>${t("rapport.client")}</td>
            <td>${ticket?.customer || "Comptoir"}</td>
          </tr>
        </table>
      </div>
     <hr class="separator" />`;
  }

  function buildFooter(mode: PaymentMode) {
    const netAndBrutDiff = mode.realOffer || mode.discount; // sold = reste à payer = crédit;
    return `<tfoot>
      ${
        netAndBrutDiff
          ? `<tr>
          <td colspan="2">${t("rapport.amount")}</td>
          <th>
            ${formatNumber(mode.cost)}
            ${activeActivity.value.currencySymbol}
          </th>
          </tr>
          <tr>
            <td colspan="2" class="p-text-uppercase">
              ${t("payment.discount")}
              ${formatNumber(mode.percentage, true)}%
            </td>
            <th>
              ${formatNumber(mode.discount)}
               ${activeActivity.value.currencySymbol}
            </th>
          </tr>
          <tr>
            <td colspan="2" class="p-text-uppercase">
              ${t("rapport.offered")}
            </td>
            <th>
              ${formatNumber(mode.realOffer)}
              ${activeActivity.value.currencySymbol}
            </th>
          </tr>`
          : ``
      }
      ${
        ticketAfterPay.payments?.length > 0
          ? `<tr>
          <td colspan="3">${t("payment.previous")}</td>
          </tr>
          ${ticketAfterPay.payments
            .map(
              (pay) => `<tr>
          <td colspan="2">
            ${formatDate(pay.createdAt, t("format.ticketDate"))}
          </td>
          <td>
            ${formatNumber(pay.received)}
            ${activeActivity.value.currencySymbol}
          </td>
          </tr>`
            )
            .join("")}`
          : ``
      }
      <tr>
        <td colspan="2" class="p-text-uppercase">
          ${t("rapport.netTotal")}
        </td>
        <th>
          ${
            mode.isOffered ||
            `${formatNumber(mode.netTotal)} ${
              activeActivity.value.currencySymbol
            }`
          }
        </th>
      </tr>
    </tfoot>`;
  }

  function getName(product: Partial<Product>, ref: string) {
    const { name, reference, discount } = productParams(product);
    if (discount) return name;
    if (printRef) {
      return `<div>${name}</div>${
        reference && `<small>(${t("product.ref")} : ${ref})</small>`
      }`;
    }
    return name;
  }

  function buildSupOrEq2OptionPrint(ticket: any, option: number) {
    const mode = getPaymentMode(ticket);
    return `<div id="print-ticket">
       ${buildHeader(ticket)}
      <table class="table-content" border="1" cellspacing="0" cellpadding="4">
        <thead>
          <tr>
            <td>${t("stock.product")}</td>
            <td>${t("payment.quantity")}</td>
            ${
              option === PrinterOptionsEnum.PrintPartial
                ? `<td>${t("pos.amount")}</td>`
                : ""
            }
          </tr>
        </thead>
        <tbody>
          ${ticket.movements
            .map(
              (mvt) => `<tr>
              <td>${getName(mvt, mvt.reference)}</td>
              <td>
                ${formatNumber(mvt.quantity)}
              </td>
              ${
                option == PrinterOptionsEnum.PrintPartial
                  ? `<td>
                ${formatNumber(mvt.quantity * mvt.price)}
                ${activeActivity.value.currencySymbol}
              </td>`
                  : ``
              }
            </tr>`
            )
            .join("")}
        </tbody>
        ${option === PrinterOptionsEnum.PrintPartial ? buildFooter(mode) : ""}
      </table>
      <hr class="separator" />
      <div id="footer">
        ${t("payment.thanks")}
      </div>
    </div>`;
  }

  function buildInf2OptionPrint(ticket: any, option: number) {
    setupTicket(ticket);

    const mode = getPaymentMode({
      ...ticketAfterPay,
      id: ticket?.id,
    });
    return `<div id="print-ticket">
      ${buildHeader(ticketAfterPay)}
      <hr class="separator" />
      <table class="table-content" border='1' cellspacing='0' cellpadding='4'>
        <thead>
          <tr>
            <td>${t("stock.product")}</td>
            <td>${t("payment.quantity")}</td>
            ${
              option != PrinterOptionsEnum.PrintNoPrice
                ? `<td>${t("payment.total")}</td>`
                : ``
            }
          </tr>
        </thead>
        <tbody>
           ${ticketAfterPay.movements
             .map(
               (mvt) => `<tr>
                <td>${getName(mvt, mvt.reference)}</td>
                <td>
                  ${formatNumber(mvt.quantity)}
                </td>
                ${
                  option != PrinterOptionsEnum.PrintNoPrice
                    ? `<td>
                  ${formatNumber(mvt.quantity * mvt.price)}
                  ${activeActivity.value.currencySymbol}
                </td>`
                    : ``
                }
              </tr>`
             )
             .join("")}
        </tbody>
        ${option != PrinterOptionsEnum.PrintNoPrice ? buildFooter(mode) : ``}
      </table>
      <hr class="separator" />
      <div id="footer">
        ${t("payment.thanks")}
      </div>
    </div>`;
  }

  function buildOption4Print(ticket: any) {
    setupTicket(ticket);
    const mode = getPaymentMode({
      ...ticketAfterPay,
      id: ticket?.id,
    });
    return `<div id="print-ticket">
      <div id="header">
        <table id="ticket">
        <tr>
          <td>${t("date")}</td>
          <td colspan="2">${formatDate(
            ticketAfterPay.createdAt,
            t("format.ticketDate")
          )}</td>
        </tr>
      </table>
      </div>
      <hr class="separator" />
      <table class="table-content" border='1' cellspacing='0' cellpadding='4'>
        <tbody>
           ${ticketAfterPay.movements
             .map(
               (mvt) => `<tr>
                <td>${getName(mvt, mvt.reference)}</td>
                <td>
                  ${formatNumber(mvt.quantity)}
                </td><td>
                  ${formatNumber(mvt.quantity * mvt.price)}
                  ${activeActivity.value.currencySymbol}
                </td>
              </tr>`
             )
             .join("")}
        </tbody>
        ${buildFooter(mode)}
      </table>
    </div>`;
  }

  function buildOption5Print(ticket: any) {
    const mode = getPaymentMode(ticket);
    return `<div id="print-ticket">
      <div id="header">
        <table id="ticket">
        <tr>
          <td>${t("date")}</td>
          <td colspan="2">
            ${formatDate(ticket.createdAt, t("format.ticketDate"))}
            </td>
        </tr>
      </table>
      </div>
      <hr class="separator" />
      <table class="table-content" border="1" cellspacing="0" cellpadding="4">
        <thead>
          <tr>
            <td>${t("stock.product")}</td>
            <td>${t("payment.quantity")}</td>
            <td>${t("pos.amount")}</td>
          </tr>
        </thead>
        <tbody>
          ${ticket.movements
            .map(
              (mvt) => `<tr>
              <td>${getName(mvt, mvt.reference)}</td>
              <td>
                ${formatNumber(mvt.quantity)}
              </td>
              <td>
                ${formatNumber(mvt.quantity * mvt.price)}
                ${activeActivity.value.currencySymbol}
              </td>
            </tr>`
            )
            .join("")}
        </tbody>
        ${buildFooter(mode)}
      </table>
    </div>`;
  }

  function printBuild(ticket: any, option: number) {
    let body = "";
    if (option < PrinterOptionsEnum.PrintPartial)
      body = buildInf2OptionPrint(ticket, option);
    // ticket epure
    else if (option == PrinterOptionsEnum.PrintEpured)
      body = buildOption4Print(ticket);
    else if (option == PrinterOptionsEnum.PrintPartialEpured)
      body = buildOption5Print(ticket);
    else body = buildSupOrEq2OptionPrint(ticket, option);
    const { getMargins } = useTerminalMargins();

    printFrame(`<!DOCTYPE html>
      <head>
        <style>
            @page {
              margin: ${getMargins()};
              padding: 0;
           }
           /*@page {
              margin: 0 9mm 0 6mm;
              padding: 0;
           }
           @-moz-document url-prefix() {
               @page {
                  margin: 0 1mm;
                  padding: 0;
              }
           }*/
           @media print {
              body {
                  font-family: Arial, serif;
                  margin: 0;
                  padding: 0;
                  font-size: 13px;
                  color: black;
                  word-break: break-all;
              }
          
              #print-ticket {
                  /*width: 57mm*/;
              }
          
              .separator {
                  margin: 3mm 0;
              }
          
              #name {
                  font-weight: bold;
                  font-size: 1.2em;
              }
          
              #desc {
                  font-size: 0.9em;
              }
          
              .ticket {
                  font-weight: bold;
                  font-size: 0.8em;
              }
          
              #ticket {
                  margin: auto;
                  width: 100%;
                  border-collapse: collapse;
              }
          
              #ticket tr td {
                  text-align: left;
                  padding: 4px;
                  border-bottom: dotted 1px black;
              }
          
              #ticket tr:last-child td {
                  border-bottom: none;
              }
          
              .table-content {
                  margin: auto;
                  width: 100%;
                  text-align: right;
                  font-size: 12px;
              }
              .table-content > thead > tr:first-child > th {
                  text-align: center !important;
              }
              .table-content > thead > tr > td {
                  font-weight: bold;
              }
          
              .table-content > thead > tr > td {
                  white-space: nowrap;
              }
          
              .table-content > thead > tr > td:first-child {
                  text-align: left;
              }
          
              .table-content > tbody > tr > td {
                  white-space: nowrap;
              }
          
              .table-content > tbody > tr > td:first-child {
                  white-space: normal !important;
                  text-align: left;
              }
          
              .table-content > tfoot > tr > td:first-child {
                  text-align: right;
                  font-weight: bold;
              }
          
              .table-content > tfoot > tr > td:last-child {
                  white-space: nowrap;
              }
          
              .table-content > tfoot > tr:last-child > td:last-child {
                  font-weight: bold;
                  font-size: 1.2em;
              }
          
              #footer {
                  text-align: center;
              }
           }
        </style>
      </head>
      <html lang="en">
        <body>
          ${body}
        </body>
      </html>`);

    const appSource = localStorage.getItem(CONSTANTS.appSource);
    if (appSource.includes(AppSource.desktop.name)) {
      btnPrinterEnable.value = 0;
      const interval = setInterval(() => {
        btnPrinterEnable.value += 1;
        if (btnPrinterEnable.value >= 3) {
          clearInterval(interval);
          btnPrinterEnable.value = 0;
        }
      }, 1000);
    }
  }

  return {
    printBuild,
    setupTicket,
    paymentDialog,
    ticketAfterPay,
    customer,
    printAfterPay,
    btnPrinterEnable,
  };
};
