import { CONSTANTS } from "@/graphql/utils/utils";
import { Terminal } from "@/graphql/types";

export const useTerminalMargins = () => {
  const browser = (function () {
    const test = function (regexp: any) {
      return regexp.test(window.navigator.userAgent);
    };
    switch (true) {
      case test(/edg/i):
        return "edge";
      case test(/trident/i):
        return "Microsoft Internet Explorer";
      case test(/firefox|fxios/i):
        return "firefox";
      case test(/opr\//i):
        return "Opera";
      case test(/ucbrowser/i):
        return "UC Browser";
      case test(/samsungbrowser/i):
        return "Samsung Browser";
      case test(/chrome|chromium|crios/i):
        return "Google Chrome";
      case test(/safari/i):
        return "safari";
      default:
        return "chrome";
    }
  })();
  function restoreDefault() {
    //[top, right, bottom, left]
    if (browser === "firefox") return [0, 0, 0, 1];
    else return [0, 9, 0, 6];
  }

  function convertMargins(m: number[]) {
    return m.map((v) => (v > 0 ? `${v}mm` : "0")).join(" ");
  }
  function getMargins(): string {
    const terminal: Terminal = JSON.parse(
      localStorage.getItem(CONSTANTS.activeTerminal)
    );

    if (!terminal || !terminal.margeActive)
      return convertMargins(restoreDefault());

    return convertMargins(terminal.printerMargins);
  }
  return { restoreDefault, getMargins, convertMargins };
};
